import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled component for background
//padding: ${props => props.theme.sizes.minHeight.xs};
export const AppBackground = styled.div`
  background-color: ${props => props.theme.colors.background};
  min-height:  ${props => props.theme.sizes.height.full};
  width: -webkit-fill-available;
  overflow: hidden;
  @media (max-width: 768px) {
    overflow: hidden;
  }
`;

export const ContainIcon = styled.img`
  font-size: ${props => props.theme.fonts.size.section_heading};
  color: ${props => props.theme.colors.text};
  cursor: pointer;
`;

export const AlignHorizon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  @media (max-width: 1008px) {
    flex-direction: column-reverse;
    gap: 1.5em !important;
  }
`;

export const AlignVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1008px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const AlignHorizonHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1008px) {
    flex-direction: row;
    margin: auto !important;
  }
`;

export const StickyHeader = styled(AlignHorizonHeader)`
  position: sticky;
  top: 0; 
  background-color: rgba(255, 255, 255, 0.25); // White background with 25% opacity
  z-index: 1000; // Ensures the header is above other elements
  width: -webkit-fill-available;
  margin: auto 0
  @media (max-width: 1008px) {
    position: sticky;
    flex-direction: column;
    margin: auto !important;
    
  }
`;

export const SmallBox = styled.div`
    height: ${props => props.theme.sizes.minHeight.xs};
    width: ${props => props.theme.sizes.minHeight.xs};
`;

export const MediumBox = styled.div`
    height: ${props => props.theme.sizes.minHeight.sm};
    width: ${props => props.theme.sizes.minHeight.sm};
`;

export const VerticalLine = styled.div`
  width: 2px; 
  height: 24px; 
  background-color: #000;
  margin: 0 4%;
`;

export const HorizontalLine = styled.div`
  height: 2px; 
  background-color: #fff;
  margin: 0 4%;
`;


export const SmalLCapText = styled.p`
  font-size: ${props => props.theme.fonts.size.medium};
  text-transform: uppercase;
  margin: 0; 
`;

export const SquareImage = styled.img`
  width: ${props => props.theme.sizes.minHeight.xxl};
  height: ${props => props.theme.sizes.minHeight.xxl};
  margin: 5%;
  border-radius: ${props => props.theme.borderRadius.medium};
  box-shadow: ${props => props.theme.shadows.large};
  object-fit: cover;

`;

export const ArticleImage = styled.img`
  width: 20vw;
  height: 20vw;
  margin: 5%;
  border-radius: 0px;
  border: solid 10px ${props => props.theme.colors.highlight};
  box-shadow: ${props => props.theme.shadows.large};
  align-items: flex-end;
  object-fit: cover;

`;

export const BubbleBorder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px .875rem 0px 0px;
  border: ${props => props.theme.spacing.xxs} solid ${props => props.theme.colors.border};
  padding: ${props => props.theme.spacing.sm};
  border-radius: ${props => props.theme.borderRadius.large};
  min-width: ${props => props.theme.sizes.minWidth.lg}
`;

export const LargeDot = styled.span`
  font-size: ${props => props.theme.fonts.size.heading}; 
  vertical-align: middle;
`;

export const ListItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const ListItemStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ddd',
  padding:'0 10px',
  width: '100%',
};