import React from 'react';
import styled from 'styled-components';
import { StandardParagraph, StandardSubHeaderText } from '../constants/styles/text';

// Define styled components
const MissionSection = styled.section`
  background-color: transparent;
  color: white;
  text-align: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    text-align: center;
  }
`;

export const MissionHeader = styled.h2`
  font-size: 1em;
  margin-bottom: 0.5em;
  color: #008080;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const MissionText = styled(StandardParagraph)`
  font-size: 3.5em;
  margin-bottom: .5em;
  margin-left: 0em;
  color: black;
  @media (max-width: 768px) {
    font-size: 1.75em !important;
    margin-left: 0;

  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  background-color: RGB(0,125,125,.25);
  background-filter: blur(15px);
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  width: 30%;

  @media (max-width: 768px) {
    width: 80%;
  }

  &:hover {
    background-color: #f4f4f4;
    text-color: white;
  }
`;

export const MXCard = styled(Card)`
  width: 95%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const CardTitle = styled(StandardSubHeaderText)`
  font-size: 1.1em !important;
  color: black;
`;

export const CardText = styled(StandardParagraph)`
  font-size: 1em !important;
  margin: 10px 0px 0px ;
  color: black;
`;

// Main component
const MissionComponent = () => {
  return (
    <MissionSection>
      <MissionHeader>OUR MISSION</MissionHeader>
      <MissionText>
        CURATING CLOSETS <br /> MASTERING STYLES <br /> ALL FLEX , NO FLASH <br /> Quiet Luxury.
      </MissionText>
      <CardsContainer>
        <Card>
          <CardTitle>Authenticity Guaranteed</CardTitle>
          <CardText>Partnered with 100+ leading luxury brands</CardText>
        </Card>
        <Card>
          <CardTitle>Sell-Out Drops</CardTitle>
          <CardText>Sign up for notifications to never miss out</CardText>
        </Card>
        <Card>
          <CardTitle>Returns Accepted</CardTitle>
          <CardText>Returns accepted on all products</CardText>
        </Card>
      </CardsContainer>
    </MissionSection>
  );
};

export default MissionComponent;
