import React from 'react';
import './modules.css';
import MainComponent, { ActionButton } from './main-component';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import BGimgOne from '../constants/images/slider-one.png';
import BGimgTwo from '../constants/images/slider-two.png';
import Header from '../constants/header';
import Santa from '../constants/images/slider-threew.png';
import MissionComponent, { CardText, CardTitle, CardsContainer, MXCard, MissionHeader, MissionText } from './mission-comp';
import { AlignHorizon, AlignVertical, HorizontalLine, MediumBox, SmallBox, SquareImage } from '../constants/styles/basic';
import PictureGrid from './picture-grid';
import SneakerBox from './box-component';
import LoadingBoxOne from '../constants/images/Boxes/womans-mystery-box.png';
import LoadingBoxTwo from '../constants/images/Boxes/mens-mystery-box.png';
import LoadingBoxThree from '../constants/images/Boxes/gender-neutral-mystery-box.png';
import LoadingBoxFour from '../constants/images/Boxes/holiday-mystery-box.png';
import Trinity from '../constants/images/trinity.jpg'
// Carousel configuration

declare global {
  interface NodeRequire {
    context: (
      directory: string,
      useSubdirectories: boolean,
      regExp: RegExp
    ) => {
      keys: () => string[];
      (id: string): any;
    };
  }
}


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000, // 10000 ms (10 seconds)
};





const sneakerBoxItems = [
  {
    backgroundImage: LoadingBoxOne,
    availabilityTag: "NOW AVAILABLE",
    title: "WOMENS MYSTERY BOX",
    price: "$310",
    guaranteeTag: "No Guarantees",
    guaranteedValue: "$575 - $650+",
    contents: "items",
    feature: "Fear of God",
    buttonTitle: "NOTIFY ME",
    lineColor: "#008080"
  },
  {
    backgroundImage: LoadingBoxTwo,
    availabilityTag: "NOW AVAILABLE",
    title: "MENS MYSTERY BOX",
    price: "$310",
    guaranteeTag: "No Guarantees",
    guaranteedValue: "$575 - $650+",
    contents: "items",
    feature: "Fear of God",
    buttonTitle: "NOTIFY ME",
    lineColor: "#008080"
  },
  {
    backgroundImage: LoadingBoxThree,
    availabilityTag: "NOW AVAILABLE",
    title: "GENDER NEUTRAL MYSTERY BOX",
    price: "$310",
    guaranteeTag: "No Guarantees",
    guaranteedValue: "$575 - $650+",
    contents: "items",
    feature: "Fear of God",
    buttonTitle: "NOTIFY ME",
    lineColor: "#008080"
  },
  {
    backgroundImage: LoadingBoxFour,
    availabilityTag: "NOW AVAILABLE",
    title: "HOLIDAY GIVEAWAY PACKAGE",
    price: "$310",
    guaranteeTag: "No Guarantees",
    guaranteedValue: "$575 - $650+",
    contents: "items",
    feature: "Fear of God",
    buttonTitle: "NOTIFY ME",
    lineColor: "#008080"
  },
  // Repeat for as many boxes as you have
  // ...
];



const items = [
  {
    backgroundImage: BGimgOne,
    tag: 'Coming Soon',
    boldHeading: 'PROFESS BOX',
    heading: 'COMING SOON',
    subtext: 'We\'ve loaded up our boxes with $100s of extra free guaranteed value for the holiday period, featuring Palm Angels & Off-White in every box.',
    buttonText: 'GET EARLY ACCESS'
  },
  {
    backgroundImage: BGimgTwo,
    tag: 'Coming Soon',
    boldHeading: 'MYSTERY BOX',
    heading: 'COMING SOON',
    subtext: 'Our curated luxury Superbrand boxes are extremely limited, with iconic pieces from global fashion houses.',
    buttonText: 'GET EARLY ACCESS'
  },
  {
    backgroundImage: Santa,
    tag: 'Now Live',
    boldHeading: 'HOLIDAY GIVEAWAY',
    heading: 'NOW LIVE',
    subtext: 'Our curated luxury Giveaway boxes are extremely limited, with iconic pieces from global fashion houses.    ',
    buttonText: 'JOIN NOW'
  },
];

function MainSearchPage() {
  const images = require.context('../../public/luxury', false, /\.png$/);

console.log(images.keys()); // Log all keys to see what is being loaded

interface ImageObject {
  src: string;
  alt: string;
}

const imageList: ImageObject[] = images.keys().map((image): ImageObject => {
  console.log(images(image)); // Log the loaded image object
  return {
    src: images(image), // If images are not exported as default
    alt: image.replace('./', '').replace('.png', ''), // Adjusted for .png extension
  };
});

console.log(imageList); // Log the final imageList


  const splitIntoGroups = <T extends any[]>(array: T, groups: number): T[][] => {
    const perGroup = Math.ceil(array.length / groups);
    return new Array(groups).fill([]).map((_, i) => {
      return array.slice(i * perGroup, (i + 1) * perGroup) as T;
    });
  };
  
  // Split the image list into four groups
  const groupedImages = splitIntoGroups(imageList, 4).map(group => group.flat());

  console.log(groupedImages[0])

  return (
    <div>
      <Header />
      <Slider {...settings}>
      {items.map((item, index) => (
        <div key={index}>
          <MainComponent
            backgroundImage={item.backgroundImage}
            tag={item.tag}
            boldHeading={item.boldHeading}
            heading={item.heading}
            subtext={item.subtext}
            buttonText={item.buttonText}
            buttonLink='https://join.lowkeylavish.com'
          />
        </div>
      ))}
    </Slider>
    <MediumBox />
    <MediumBox />
    <HorizontalLine />
    <SmallBox />
    <MissionComponent />
    <SmallBox />
    <HorizontalLine />
    <SmallBox />
        <AlignVertical>
      <MissionHeader> OUR CLOSET </MissionHeader>
      <MissionText style={{margin: 'auto', marginBottom: '5%'}}> The Quality Speaks for Itself</MissionText>
      </AlignVertical>
      <PictureGrid images={groupedImages} />
    <SmallBox />
    <HorizontalLine />
    <SmallBox />
    <AlignVertical>
      <MissionHeader> PRODUCTS </MissionHeader>
      <MissionText style={{margin: 'auto', marginBottom: '5%'}}> CURATED PERFECTION</MissionText>
      </AlignVertical>

      <AlignHorizon>
      <AlignHorizon style={{gap: '4rem'}}>
    {sneakerBoxItems.map((item, index) => (
      <div key={index}>
        <SneakerBox
          backgroundImage={item.backgroundImage}
          availabilityTag={item.availabilityTag}
          title={item.title}
          price={item.price}
          guaranteeTag={item.guaranteeTag}
          guaranteedValue={item.guaranteedValue}
          contents={item.contents}
          feature={item.feature}
          buttonTitle={item.buttonTitle}
          lineColor={item.lineColor}
        />
      </div>
    ))}
  </AlignHorizon>
      </AlignHorizon>
      <MediumBox />
      <HorizontalLine />
      <MediumBox />
      <AlignVertical>
      <MissionHeader> YOUR STYLIST </MissionHeader>
      <MissionText style={{margin: 'auto', marginBottom: '5%'}}> LAVISH PROFILE v1</MissionText>
      </AlignVertical>
      <AlignHorizon>
        <SquareImage src={Trinity} style={{height: '50vh', width: '50vh'}}/>
        <AlignVertical>
          <CardsContainer>
          <MXCard>
            <CardTitle>Authenticity Guaranteed</CardTitle>
            <CardText>Partnered with 100+ leading luxury brands</CardText>
          </MXCard>
          <MXCard>
            <CardTitle>Sell-Out Drops</CardTitle>
            <CardText>Sign up for notifications to never miss out</CardText>
          </MXCard>
          <MXCard>
            <CardTitle>Returns Accepted</CardTitle>
            <CardText>Returns accepted on all products</CardText>
          </MXCard>
          <a href='https://join.lowkeylavish.com' style={{textDecoration: 'none', color: 'white', width: '95%'}}><ActionButton style={{width: '100%'}}> NOTIFY ME</ActionButton>
          </a>
        </CardsContainer>
        </AlignVertical>
      </AlignHorizon>

    </div>
  );
};

export default MainSearchPage;
