import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AlignVertical, SmallBox } from '../constants/styles/basic';
import { ActionButton } from './main-component';

// Define the types for your component's props
type SneakerBoxProps = {
  backgroundImage: string;
  availabilityTag: string;
  title: string;
  price: string;
  guaranteeTag: string;
  guaranteedValue: string;
  contents: string;
  feature: string;
  buttonTitle: string;
  lineColor: string;
};

type ContainerProps = {
    backgroundImage: string;
    lineColor: string;
  };
  
  // Use the defined type for the styled component
  const Container = styled(motion.div)<ContainerProps>`
  background-image: url(${(props) => props.backgroundImage});
  color: white;
  padding: 20px;
  width: 300px;
  height: 450px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border: 5px solid ${(props) => props.lineColor};
  border-radius: 25px;
  overflow: hidden; // Ensure the overlay is contained within the border-radius

  // Gradient overlay
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
    pointer-events: none; // Allows click events to pass through to elements underneath
  }
`;

const AvailabilityTag = styled.div`
  background-color: #008080;
  padding: 10px;
  position: absolute;
  border-radius: 15px  0px 15px 0px;
  top: 0px;
  left: 0px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-top: 40px;
  text-align: center;
  
`;

const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Guarantee = styled.div`
  font-size: 16px;
`;

const Content = styled.div`
  font-size: 16px;
  margin: auto 0;
  text-align: left;
`;

const Feature = styled.div`
  font-size: 16px;
`;

// Define your component using the types and styled components
const SneakerBox: React.FC<SneakerBoxProps> = ({
  backgroundImage,
  availabilityTag,
  title,
  price,
  guaranteeTag,
  guaranteedValue,
  contents,
  feature,
  buttonTitle,
  lineColor,
}) => {
  return (
    <Container backgroundImage={backgroundImage} lineColor={lineColor}>
      <AlignVertical style={{justifyContent: 'space-between', alignItems: 'space-between', height: 'inherit', margin: 0}}>
      <AvailabilityTag>{availabilityTag}</AvailabilityTag>
      <SmallBox />
      <AlignVertical style={{margin: ' 0', zIndex: '10'}}>
        <Title>{title}</Title>
        <Price>FROM {price}</Price>
        <Guarantee style={{textAlign: 'left'}}>{guaranteeTag}</Guarantee>
        <Content>{`Guaranteed Value: ${guaranteedValue}`}</Content>
        <Content>{`Contains: ${contents}`}</Content>
        <Feature>{`Featuring: ${feature}`}</Feature>
        <a href='https://join.lowkeylavish.com' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}><ActionButton>{buttonTitle}</ActionButton></a>
      </AlignVertical>
      </AlignVertical>
    </Container>
  );
};

export default SneakerBox;
