import React from 'react';
import LogoImg from './images/new-logo.png';
import { Link } from 'react-router-dom';
import { AlignHorizonHeader, ContainIcon, StickyHeader } from './styles/basic';
import { HeaderText} from './styles/text';
import { ActionButton } from '../joinNewsletter/main-component';



function Header() {


  return (
    <>
      <StickyHeader as="nav" style={{justifyContent:'space-between', margin: 'auto', flexDirection: 'row', alignContent: 'center', width: '90%'}}>
      <a href='https://news.lowkeylavish.com/articles' style={{textDecoration: 'None', color: 'black', margin: 'auto 0', display: 'none'}}> <HeaderText style={{margin: 'auto'}}> News</HeaderText> </a>
        <AlignHorizonHeader>
        <Link to="/"> <ContainIcon src={LogoImg} style={{width: '50px', height: '50px'}}/> </Link>
        <HeaderText>Lowkey Lavish</HeaderText>
        </AlignHorizonHeader>
        <a href='https://join.lowkeylavish.com' style={{textDecoration: 'None', display: 'none'}}><ActionButton style={{margin: 'auto'}}> STAY NOTIFIED</ActionButton> </a>
      </StickyHeader>
    </>
  );
}

export default Header;


