import React from 'react';
import styled from 'styled-components';
import { StandardHeadTxt, StandardSubHeaderText, StandardHeaderText } from '../constants/styles/text';
import { AlignVertical } from '../constants/styles/basic';
import '@fontsource-variable/lora';


interface MainComponentProps {
    backgroundImage: string;
    tag: string;
    boldHeading: string;
    heading: string;
    subtext: string;
    buttonText: string;
    boldHeadingColor?: string;
    buttonLink: string;
  }


const SectionContainer = styled.section<{ backgroundImage: string }>`
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  margin: 35px 0px;
  height: 80vh;

  @media (max-width: 768px) {
    background-size: cover;
  }
`;

const Tag = styled(StandardHeadTxt)`
  font-weight: bold;
  margin: auto 0;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
  color: white;
  padding: 5px;
  background-color: RGB(0,0,0,.5);
  border-radius: 5px;
  border: solid 1px white;
`;

const BoldHeading = styled(StandardHeaderText)`
  font-size: 60px;
  font-weight: 800;
  color: white;
`;

const SubText = styled(StandardSubHeaderText)`
    margin: 0
    margin-top: 20px;
    text-align: center;
    color: black;
    font-size: 16px;
    max-width: 75%;

    @media (max-width: 768px) {
        max-width: 100%;
      }
`;

const Containers = styled(AlignVertical)`
    margin-bottom: 0%;
    padding: 30px;
    background-color: RGB(0,128,128,.5);
    backdrop-filter: blur(15px);
    border-radius: 15px 15px 0px 0px;
    width: fit-content;
    max-width: 50%;
    border: solid 1px white;
    @media (max-width: 1008px) {
        max-width: 100%;
        margin-bottom: 0%;
      }
`;


export const ActionButton = styled.button`
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.7); // white box shadow
  transition: transform 0.1s ease; // smooth transition for the transform
  &:active {
    transform: scale(0.9); // scale down button when clicked
  }

  &:hover {
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.9); // slightly larger box shadow on hover
  }
`;

const MainComponent: React.FC<MainComponentProps> = ({
    backgroundImage,
    tag,
    boldHeading,
    heading,
    subtext,
    buttonText,
    buttonLink,
    boldHeadingColor = '#fff'
  }) => {
  return (
    <SectionContainer backgroundImage={backgroundImage}>
      <Containers>
        <Tag>{tag}</Tag>
        <BoldHeading>{boldHeading}<br/><span style={{fontWeight: '300', fontFamily: '\'Lora Variable\', sans-serif'}}>{heading}</span></BoldHeading>
        <SubText>{subtext}</SubText>
        <a href= {buttonLink} style={{color: 'black', textDecoration: 'none'}}><ActionButton>{buttonText}</ActionButton></a>
      </Containers>
    </SectionContainer>
  );
};

export default MainComponent;
