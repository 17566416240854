import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

interface ImageObject {
  src: string | any;
  alt: string | any;
}

interface PictureGridProps {
  images: ImageObject[][]; // Assuming images is an array of arrays
}


const Overlay = styled.div`
  position: relative;
`;

const ShadowOverlay = styled.div`
  position: absolute;
  z-index: 10; // Ensure it's above the images
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 40px 0px 10px rgba(255, 255, 255, 0.8), inset -40px 0px 10px rgba(255, 255, 255, 0.8);
  
`;

const GridContainer = styled(motion.div)`
  display: flex;
  flex-direction: column; // To align rows vertically
  gap: 20px; // Space between rows
  padding: 20px;
  background-color: #f7f7f7;
  overflow: hidden;
`;

interface ImageRowProps {
    images: ImageObject[]; // Assuming this is the correct type for your images
    minWidth: string; // The dynamic width calculated for the row
  }


const calculateRowWidth = (imageRow: any) => {
    // Assuming each image card has a fixed width of 200px and a gap of 10px
    const imageCardWidth = 200;
    const gap = 10;
    const totalWidth = (imageCardWidth + gap) * imageRow.length - gap; // Subtract one gap as it's not needed after the last image
    return totalWidth;
  };
  
  // Adjusted ImageRow to use a dynamic width based on the images it contains
  const ImageRow = styled(motion.div)<ImageRowProps>`
  position: relative;
  display: flex;
  overflow-x: auto;
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  min-width: ${props => props.minWidth}; // Use the passed minWidth prop
`;


const ImageCard = styled(motion.div)`
  flex: 0 0 auto;
  width: 240px; // Fixed width for each image card
  height: auto; // Fixed height for each image card
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  @media (max-width: 1008px) {
    width: 200px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const scrollVariants = {
    normal: {
      x: ['-50%' , '0%'], // Move from 0 to -50% of the container width
      transition: {
        duration: 45, // Normal speed
        ease: "linear",
        repeat: Infinity,
        repeatType: "loop" as const // Continuous loop without reversing
      },
    },
    fast: {
      x: ['0%' , '-50%'], // Move from 0 to -50% of the container width
      transition: {
        duration: 45, // Faster speed
        ease: "linear",
        repeat: Infinity,
        repeatType: "loop" as const // Continuous loop without reversing
      },
    },
  };
  
  const PictureGrid: React.FC<PictureGridProps> = ({ images }) => {
    return (
        <Overlay>
        <ShadowOverlay />
        <GridContainer>
          {images.map((imageRow, rowIndex) => {
            // Calculate the row width based on the number of images
            const rowWidth = calculateRowWidth([...imageRow, ...imageRow]); // Duplicate the images for scrolling
            return (
              <ImageRow
                key={rowIndex}
                style={{ minWidth: `${rowWidth}px` }} // Apply the dynamic width here
                variants={scrollVariants}
                animate={rowIndex % 2 === 0 ? "fast" : "normal"}
              >
                {[...imageRow, ...imageRow].map((image, imageIndex) => (
                  <ImageCard key={imageIndex}>
                    <Image src={image?.src} alt={image?.alt} />
                  </ImageCard>
                ))}
              </ImageRow>
            );
          })}
        </GridContainer>
      </Overlay>
    );
  };
  
  export default PictureGrid;