import React from 'react';
import { lightTheme, darkTheme } from './themes'; 
import { ThemeProvider } from 'styled-components';
import { AppBackground } from './constants/styles/basic';
import Footer from './constants/footer';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainSearchPage from './joinNewsletter/join-newsletter-stew';
import ShootingStarAnimation from './constants/shooting-star';




function App() {
  const currentTheme = 'dark';
  const theme = currentTheme === 'dark' ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={theme}>
      <AppBackground>
        <ShootingStarAnimation />
        
        <Router>
        
        <Routes>
          <Route path="/" element={<MainSearchPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
      </AppBackground>
    </ThemeProvider>
  );
}

export default App;
