import React from 'react';
import './styles.css';
const ShootingStarAnimation: React.FC = () => {
  const stars = Array.from({ length: 9 }, (_, index) => index + 1);

  const starStyle = (index: number): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
      position: 'absolute',
      width: '4px',
      height: '4px',
      background: '#000',
      borderRadius: '50%',
      boxShadow: '0 0 0 4px rgba(0,125,125,0.1), 0 0 0 8px rgba(0,125,125,0.1), 0 0 20px rgba(0,125,125,0.1)',
      transform: 'rotate(315deg)',
      opacity: 1,
      
    };

    const customStyles = [
        { ...baseStyle, top: '0', right: '0', left: 'initial', animation: 'animate 1s linear infinite', animationDelay: '0s' },
        { ...baseStyle, top: '0', right: '80px', left: 'initial', animation: 'animate 3s linear infinite', animationDelay: '0.2s' },
        { ...baseStyle, top: '80px', right: '0px', left: 'initial', animation: 'animate 2s linear infinite', animationDelay: '0.4s' },
        { ...baseStyle, top: '0', right: '180px', left: 'initial', animation: 'animate 1.5s linear infinite', animationDelay: '0.6s' },
        { ...baseStyle, top: '0', right: '400px', left: 'initial', animation: 'animate 2.5s linear infinite', animationDelay: '0.8s' },
        { ...baseStyle, top: '0', right: '600px', left: 'initial', animation: 'animate 3s linear infinite', animationDelay: '.2s' },
        { ...baseStyle, top: '300px', right: '0px', left: 'initial', animation: 'animate 1.75s linear infinite', animationDelay: '0.32s' },
        { ...baseStyle, top: '0px', right: '700px', left: 'initial', animation: 'animate 1.25s linear infinite', animationDelay: '0.54s' },
        { ...baseStyle, top: '0px', right: '450px', left: 'initial', animation: 'animate 2.75s linear infinite', animationDelay: '.75s' },
      ];

    return customStyles[index - 1];
  };

  return (
    <section style={{ position: 'fixed', top: 0, left: 0, width: '100%', minHeight: '100vh', zIndex:10, background: 'transparent', overflow: 'hidden', pointerEvents: 'none' }}>
      {stars.map(index => (
         <span key={index} className="star" style={starStyle(index)} />
      ))}
    </section>
  );
};

export default ShootingStarAnimation;
